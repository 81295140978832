import Flickity from 'flickity';
import 'flickity-fade';
import 'flickity-imagesloaded';

import { adjustCarouselBtns } from '../adjust_carousel_btns.js';

function changeThumbnailOnDrag(gallery) {
  const thumbs = document.getElementsByClassName('gallery-thumbnail');
  const thumbsArray = Array.from(thumbs);
  const flickity = Flickity.data(gallery);

  flickity.on('select', () => {
    const videos = gallery.getElementsByTagName('video');
    if (videos) {
      Array.from(videos).forEach(video => {
        if (!video.paused) {
          video.pause();
        }
      });
    }
    deselectItem(thumbsArray);
    thumbsArray[flickity.selectedIndex].classList.add('is-selected');
  });
}

function deselectItem(arr) {
  const selected = arr.filter(item => item.classList.contains('is-selected'))[0];
  selected.classList.remove('is-selected');
}

function selectSlide(thumbnail, gallery) {
  const parent = thumbnail.parentNode;
  const container = parent.parentNode;
  const sibs = [...parent.children];
  const index = sibs.indexOf(thumbnail);
  const flickity = Flickity.data(gallery);

  deselectItem(sibs);
  thumbnail.classList.add('is-selected');
  flickity.select(index);
}

function listenForThumbnailClick(gallery) {
  const thumbs = document.getElementsByClassName('gallery-thumbnail');
  Array.from(thumbs).forEach(thumbnail => {
    thumbnail.addEventListener('click', () => {
      selectSlide(thumbnail, gallery);
    });
  });
}

function revealThumbnails(gallery) {
  const bound = gallery.parentNode;

  window.setTimeout(function() {
    bound.classList.add('visible');
  }, 600);
}

function loadMediaGalleries(galleryEls) {
  galleryEls.forEach(gallery => {
    const flickity = new Flickity(gallery, {
      adaptiveHeight: false,
      bgLazyLoad: true,
      cellAlign: 'center',
      contain: true,
      fade: true,
      groupCells: false,
      imagesLoaded: true,
      lazyLoad: true,
      pageDots: true,
      percentPosition: false,
      arrowShape: {
        x0: 10,
        x1: 50, y1: 50,
        x2: 70, y2: 50,
        x3: 30
      }
    });

    adjustCarouselBtns(gallery);
    revealThumbnails(gallery);
    listenForThumbnailClick(gallery);
    changeThumbnailOnDrag(gallery);
  });
};

export function checkForMediaGalleries() {
  const galleryEls = document.querySelectorAll('.media_gallery');

  if (galleryEls.length) {
    loadMediaGalleries(galleryEls);
  }
}
