function checkForSwap(billboards) {

  billboards.forEach(billboard => {
    const swap = billboard.dataset.orderSwap;

    if (swap == 'invert') {
      const buckets = billboard.querySelector('.content-buckets');
      const art = billboard.querySelector('.art');
      const vid = billboard.querySelector('.foreground-video');

      if (art) {
        buckets.insertBefore(art, buckets.firstChild);
      }

      if (vid) {
        buckets.insertBefore(vid, buckets.firstChild);
      }
    }
  });
}

export function swapBillboards() {
  const billboards = document.querySelectorAll(".content-block[data-block-type='billboard']");

  if (billboards.length) {
    checkForSwap(billboards);
  }
}
