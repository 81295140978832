import Vue from 'vue';
import PortalVue from 'portal-vue';
import './jquery-global.js';
import isotope from 'vueisotope';

// Components
import InlineModal from './components/InlineModal';
import MobileMenuToggle from './mobile_menu_toggle';
import MosaicGrid from './blocks/mosaic_grid';

// Scripts
import { checkForAbImages } from './blocks/ab_image_drag.js';
import { checkForBackToTop } from './back_to_top.js';
import { checkForBillboardSliders } from './blocks/billboard_slider.js';
import { checkForBillboardTabs } from './blocks/billboard_tabs.js';
import { checkForForms } from './blocks/form.js';
import { checkForMediaCarousels } from './blocks/media_carousel.js';
import { checkForMediaGalleries } from './blocks/media_gallery.js';
import { checkForMultiPaneContent } from './blocks/multi_pane_content.js';
import { checkForTimelapse } from './blocks/timelapse_drag.js';
import { checkForTimelines } from './blocks/timeline.js';
import { checkForQuoteCarousels } from './blocks/quote_carousel.js';
import { listenForLoadMore } from './load_more.js';
import { swapBillboards } from './billboard_swap.js';
import { checkForLinkify } from './linkify.js';

import Flickity from 'flickity';
import 'regenerator-runtime';

Flickity.defaults.arrowShape = {
  x0: 20,
  x1: 55, y1: 50,
  x2: 70, y2: 45,
  x3: 40
};

Vue.use(PortalVue)

window.Vue = new Vue({
  el: "#app",
  delimiters: ['${', '}'],
  components: {
    isotope,
    MobileMenuToggle,
    InlineModal,
    MosaicGrid
  },
  computed: {
    list: function () {
      // return array;
    }
  },
  data: function() {
    return {
      modal: null,
    }
  },
  watch: {
    //
  },
  mounted() {
    const createModalFromQuery = () => {
      const params = new URLSearchParams(location.search);
      const target_id = params.get('image');

      if (target_id) {
        this.modalCreate(target_id);
      }
    }

    createModalFromQuery();
  },
  methods: {
    modalCreate(target_id, collection=null) {
      target_id = Number(target_id);
      this.modal = {
        current_entry_id: target_id,
        collection: collection || [target_id]
      }
    },
    modalDestroy() {
      this.modal = null;

      history.pushState('', document.title, location.pathname);
    },
    modalLoaded() {
      if (this.$refs.dynamicGrid) {
        this.$refs.dynamicGrid.closeLoading();
      }
    },
  },
});

checkForAbImages();
checkForBackToTop();
checkForBillboardSliders();
checkForBillboardTabs();
checkForForms();
checkForMediaCarousels();
checkForMediaGalleries();
checkForMultiPaneContent();
checkForTimelapse();
checkForTimelines();
checkForQuoteCarousels();
listenForLoadMore();
swapBillboards();
// watchForMenuClick();
checkForLinkify();
