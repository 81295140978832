import Flickity from 'flickity';
import 'flickity-imagesloaded';

import { adjustCarouselBtns } from '../adjust_carousel_btns.js';

function loadTimelines(timelines) {
  timelines.forEach(timeline => {
    const flickity = new Flickity(timeline, {
      adaptiveHeight: false,
      bgLazyLoad: true,
      cellAlign: 'center',
      contain: false,
      groupCells: false,
      imagesLoaded: true,
      lazyLoad: 1,
      pageDots: false,
      percentPosition: false,
      arrowShape: {
        x0: 10,
        x1: 50, y1: 50,
        x2: 70, y2: 50,
        x3: 30
      }
    });

    adjustCarouselBtns(timeline);
    attachTicks(timeline);
    adjustImageMask(timeline);
  });
}

function adjustImageMask(timeline) {
  const allCards = Array.from(timeline.querySelectorAll('.table-card'));
  const cardsWithMask = allCards.filter(card => card.querySelectorAll('.image-mask').length);

  cardsWithMask.forEach(mask => {
    const maskWidth = parseFloat(window.getComputedStyle(mask).width);
    const img = mask.querySelector('img');
    const imgWidth = img.naturalWidth;

    if (imgWidth < maskWidth) {
      img.style.width = '100%';
      img.style.height = 'auto';
    }
  });
}

function attachTicks(timeline) {
  const flickityViewport = timeline.querySelector('.flickity-viewport');
  const html = '<div class="timeline-ticks"></div>';
  flickityViewport.insertAdjacentHTML('beforebegin', html);
}

export function checkForTimelines() {
  const timelines = document.querySelectorAll('.timeline-carousel');

  if (timelines.length) {
    loadTimelines(timelines);
  }
}
