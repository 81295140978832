export default {
  props: [],
  data() {
    return {
      isMenuOpen: false
    }
  },
  computed: {
    label() {
      let dynamic = this.isMenuOpen ? 'Close' : 'Open';
      return dynamic + " Menu";
    },
    evalClasses() {
      return this.isMenuOpen ? 'active' : '';
    }
  },
  mounted() {
    //
  },
  watch: {
    'isMenuOpen': {
      handler: function(newValue) {
        let $class = 'menu_open', 
            $el = document.querySelector('body');

        if (newValue) {
          $el.classList.add($class);
        } else {
          $el.classList.remove($class);
        }
      },
      immediate: true
    }
  },
  render(h) {
    return this.$scopedSlots.default({
      toggle: this.toggle,
      close: this.close,
      open: this.open,
      label: this.label,
      isMenuOpen: this.isMenuOpen,
      class: this.evalClasses
    })
  },
  methods: {
    toggle() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    close() {
      this.isMenuOpen = false;
    },
    open() {
      this.isMenuOpen = true;
    }
  },
};