import './vendor/linkify.js';
import './vendor/linkify-jquery.js';
import './vendor/linkify-plugin-hashtag.js';
import './vendor/linkify-plugin-mention.js';

export function checkForLinkify() {

    $('.linkify').linkify({
        target: '_blank',
        formatHref: function (href, type) {
            if (type === 'hashtag') {
                href = 'https://twitter.com/hashtag/' + href.substring(1);
            } else if (type === 'mention') {
                href = 'https://twitter.com/' + href.substring(1);
            }

            return href;
        }
    });

}