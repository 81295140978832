function adjustButtons(element, dots) {

  // Get the buttons
  var prev = element.querySelectorAll('.flickity-prev-next-button.previous')[0];
  var next = element.querySelectorAll('.flickity-prev-next-button.next')[0];

  // Wrap the dots in a div
  if (!dots.parentNode.classList.contains('button-row')) {
    const wrapper = document.createElement('div');
    wrapper.classList.add('button-row');
    dots.parentNode.insertBefore(wrapper, dots);
    wrapper.appendChild(dots);
  }

  // Select the new button row div
  var btnRow = element.querySelectorAll('.button-row')[0];

  // Move buttons to it
  btnRow.insertBefore(prev, btnRow.firstChild);
  btnRow.appendChild(next);
}

export function adjustCarouselBtns(element) {
  var flktyDots = element.querySelectorAll('.flickity-page-dots');

  if (flktyDots.length) {
    adjustButtons(element, flktyDots[0]);
  }
}
