function openDesiredPane(pane) {
  pane.dataset.status = 'open';
}

function updateTabsTrack(pane, index) {
  const multiPane = pane.parentNode.parentNode;
  const tabsTrack = multiPane.querySelector('.tabs-track');
  let labels = [];

  if (tabsTrack) {
    labels = [...tabsTrack.children];
  } else {
    return;
  }

  labels.forEach(label => {
    label.classList.add('inactive');
    label.classList.remove('active');
  });

  labels[index].classList.add('active');
  labels[index].classList.remove('inactive');
}

function closeAllPanes(pane) {
  const panes = [...pane.parentNode.children].map(pane => {
    pane.dataset.status = 'shut';
    pane.dataset.exempt = 'false';
  });

  pane.dataset.exempt = true;
}

function bladeClicked(blade) {
  const pane = blade.parentNode;
  const index = [...pane.parentNode.children].indexOf(pane);
  const status = pane.dataset.status;
  closeAllPanes(pane);

  if (status == 'shut') {
    openDesiredPane(pane);
  }

  updateTabsTrack(pane, index);
}

function tabClicked(tab, multiPane) {
  const index = [...tab.parentNode.children].indexOf(tab);
  const status = tab.classList[0];

  if (status == 'inactive') {
    const panes = multiPane.querySelector('.stack-of-accordion-panes').children;
    const pane = [...panes][index];
    const tabs = [...tab.parentNode.children];

    tabs.forEach((tab, tabIndex) => {
      if (tab.classList.contains('active')) {
        tab.classList.remove('active');
        tab.classList.add('inactive');
      }

      if (tabIndex == index) {
        tab.classList.add('active');
        tab.classList.remove('inactive');
      }
    });

    closeAllPanes(pane);
    openDesiredPane(pane);
  }
}

function loadMultiPaneContent(multiPanes) {
  multiPanes.forEach(multiPane => {
    let blades = multiPane.querySelectorAll('.pane-blade');
    let tabs = multiPane.querySelector('.tabs-track');

    tabs = tabs ? [...tabs.children] : []
    blades = blades ? Array.from(blades) : []

    tabs.forEach(tab => {
      tab.addEventListener('click', () => tabClicked(tab, multiPane));
    });

    blades.forEach(blade => {
      blade.addEventListener('click', () => bladeClicked(blade));
    });
  });
}

export function checkForMultiPaneContent() {
  const multiPanes = document.querySelectorAll('.multi-pane-content');

  if (multiPanes.length) {
    loadMultiPaneContent(multiPanes);
  }
}
