export function listenForLoadMore() {
  const btns = document.getElementsByClassName('button-loadmore');
  Array.from(btns).forEach((button, index) => {
    hideLoadMore(button, index);
    button.addEventListener('click', appendMoreData.bind(button, index));
  });
};

async function appendMoreData(index) {
  toggleButton(this, 'off');
  const containerClass = this.dataset.container;
  const container = document.getElementsByClassName(containerClass)[index];
  const notVisible = Array.from(container.getElementsByClassName('invisible'))
  const remaining = this.querySelector('.total-items');

  notVisible.forEach((item, index) => {
    if (index >= this.dataset.limit) { return; }
    item.classList.remove('invisible');
    item.classList.add('visible');
  });

  const loaded = Array.from(container.getElementsByClassName('visible'));
  remaining.innerHTML = this.dataset.total - (loaded.length);

  hideLoadMore(this, index);
  toggleButton(this, 'on');
}

function toggleButton(button, enable) {
  if (enable == 'off') {
    button.classList.add('loading');
    button.style.pointerEvents = 'none';
  }
  if (enable == 'on') {
    button.classList.remove('loading');
    button.style.pointerEvents = 'auto';
  }
}

function hideLoadMore(button, index) {
  const container = document.getElementsByClassName(button.dataset.container)[index];
  const loaded = Array.from(container.getElementsByClassName('visible'));
  if (button.dataset.total == loaded.length) {
    button.style.display = 'none';
  }
}
