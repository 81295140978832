export const cache = {
  container: null,
  dragger: null,
  draggerWidth: null,
  pageX: 0,
  get widthValue() { return getWidthValue(this.pageX); }
};

export function drag(dragger, container, padding = 0) {
  cache['dragger'] = dragger;
  cache['container'] = container;
  cache['moveable'] = true;
  cache['draggerPosition'] = getDraggerPosition(dragger);
  cache['containerOffset'] = getContainerOffset(container);
  cache['minLeft'] = cache.containerOffset + padding;
  cache['maxLeft'] = cache.containerOffset + container.offsetWidth - cache.dragger.offsetWidth - padding;

  cache.dragger.classList.add('draggable');
  cache.container.addEventListener('mouseup', stopDragging);
  cache.container.addEventListener('mouseleave', stopDragging);
  cache.container.addEventListener('mousemove', moveDragger);
};

var stopDragging = function() {
  cache.moveable = false;
  cache.dragger.classList.remove('draggable');
  cache.container.removeEventListener('mousemove', moveDragger);
  cache.container.removeEventListener('mouseup', stopDragging);
  cache.container.removeEventListener('mouseleave', stopDragging);
}

var moveDragger = function() {
  cache.pageX = event.pageX;
  cache.dragger.style.left = `${cache.widthValue}%`;
}

function getLeftValue(pageX) {
  var leftValue = pageX + cache.draggerPosition - cache.dragger.offsetWidth;

  if (leftValue < cache.minLeft) {
    leftValue = cache.minLeft;
  } else if (leftValue > cache.maxLeft) {
    leftValue = cache.maxLeft;
  }

  return leftValue;
}

function getWidthValue(pageX) {
  if (cache.draggerWidth === null) {
    cache.draggerWidth = cache.dragger.offsetWidth;
  }

  const leftValue = getLeftValue(pageX);
  const halfDragger = cache.draggerWidth / 2;
  const width = leftValue + halfDragger - cache.containerOffset;

  return width * 100 / cache.container.offsetWidth;
}

function getDraggerPosition(dragger) {
  const dragBounds = dragger.getBoundingClientRect();
  const draggerOffset = dragBounds.left + document.body.scrollLeft;
  return draggerOffset + dragger.offsetWidth - event.pageX
}

function getContainerOffset(container) {
  const containerBounds = cache.container.getBoundingClientRect();
  return containerBounds.left + document.body.scrollLeft;
}
