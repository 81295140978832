import Flickity from 'flickity';
import 'flickity-imagesloaded';

import { adjustCarouselBtns } from '../adjust_carousel_btns.js';

function loadBillboardTabs(billboards) {
  billboards.forEach(billboard => {
    const thumbnails = billboard.querySelectorAll('.tab-thumbnail');
    const flickity = new Flickity(billboard, {
      adaptiveHeight: true,
      bgLazyLoad: true,
      contain: true,
      cellAlign: 'center',
      groupCells: false,
      imagesLoaded: true,
      lazyLoad: true,
      percentPosition: false
    });

    adjustCarouselBtns(billboard);

    thumbnails.forEach(thumbnail => {
      thumbnail.addEventListener('click', (event) => {
        const parent = event.currentTarget.parentNode;
        const thumbIndex = [...parent.children].indexOf(event.currentTarget);
        const slider = billboard.querySelector('.flickity-slider');

        slider.querySelectorAll('.tab-thumbnail').forEach(thumbnail => {
          if (thumbnail.classList.contains('is-selected')) {
            thumbnail.classList.remove('is-selected');
          }
        });

        slider.querySelectorAll('.button-group').forEach(group => {
          [...group.children].forEach((child, index) => {
            if (index == thumbIndex) {
              child.classList.add('is-selected');
            }
          });
        });

        flickity.select(thumbIndex);
      });
    });

    flickity.on('select', () => {
      assignNightMode(billboard, flickity.selectedElement);
    });

    adjustSelectedOnSlideChange(billboard, flickity);
    assignNightMode(billboard, flickity.selectedElement);
  });
}

function adjustSelectedOnSlideChange(billboard, flickity) {
  const tabGroup = billboard.querySelectorAll('.button-group');
  const selected = billboard.querySelector('.is-selected');
  billboard.addEventListener('select.flickity', () => {
    selected.classList.remove('is-selected');
    tabGroup.forEach(group => {
      const tabs = group.querySelectorAll('.tab-thumbnail');
      tabs.forEach((tab, index) => {
        if (index == flickity.selectedIndex) {
          tab.classList.add('is-selected');
        }
      });
    });
  });
}

function adjustBillboardTabWrapper() {
  const billboardBlocks = document.querySelectorAll('[data-block-type="billboard-tabs"]');
  billboardBlocks.forEach(block => {

    const billboardTabs = block.querySelector('.billboard-tabs');
    const buttonGroup = block.querySelector('.button-group');

    if (billboardTabs.classList.contains('flickity-enabled')) {
      block.dataset.visible = 'visible';
    }

    if (buttonGroup.style.textAlign == 'left') {
      const cells = block.querySelectorAll('.cell-wrapper');
      cells.forEach(cell => {
        cell.insertBefore(cell.querySelector('.tab-row'), cell.firstChild);
        cell.insertBefore(cell.querySelector('.tab-icon'), cell.firstChild);
      });
    }
  });
}

function assignNightMode(billboard, currentSlide) {
  const nightMode = currentSlide.dataset.nightMode;
  billboard.dataset.nightMode = nightMode;
}

export function checkForBillboardTabs() {
  const billboards = document.querySelectorAll('.billboard-tabs');
  if (billboards.length) {
    loadBillboardTabs(billboards);
    adjustBillboardTabWrapper();
  }
}
