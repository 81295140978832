import Flickity from 'flickity';
import 'flickity-imagesloaded';

import { adjustCarouselBtns } from '../adjust_carousel_btns.js';

var settings = {
  adaptiveHeight: true,
  bgLazyLoad: true,
  contain: true,
  imagesLoaded: true,
  groupCells: true,
  lazyLoad: 1,
  percentPosition: false,
  setGallerySize: true,
  wrapAround: false,
  arrowShape: {
    x0: 10,
    x1: 50, y1: 50,
    x2: 70, y2: 50,
    x3: 30
  }
};

function loadMediaCarousels() {
  const windowSize = document.documentElement.clientWidth;

  createCarousels();
  onWindowResize();
};

function onWindowResize() {
  window.addEventListener('resize', updateCarousels);
}

function updateCarousels() {
  const windowSize = document.documentElement.clientWidth;
}

function createCarousels() {
  const carouselEls = document.querySelectorAll('.media_carousel');

  carouselEls.forEach(carousel => {
    settings.setGallerySize = carousel.dataset.carouselType !== 'fullscreen';
    new Flickity(carousel, settings);
    adjustCarouselBtns(carousel);
  });
}

export function checkForMediaCarousels() {
  const carouselEls = document.querySelectorAll('.media_carousel');

  if (carouselEls.length) {
    loadMediaCarousels();
  }
}
