import Flickity from 'flickity';
import 'flickity-imagesloaded';

import { adjustCarouselBtns } from '../adjust_carousel_btns.js';

function loadBillboardSliders(billboards) {
  billboards.forEach(billboard => {
    const timer = billboard.dataset.timerOption;
    const autoplayOption = billboard.dataset.autoplayOption;
    const adaptiveHeight = billboard.dataset.heightOption != 1;
    var autoPlay = false;
    var setWrapAround = false;

    if (timer > 0 && autoplayOption == 1) {
      autoPlay = timer * 1000;
      setWrapAround = true;
    }

    const flickity = new Flickity(billboard, {
      adaptiveHeight: adaptiveHeight,
      autoPlay: autoPlay,
      bgLazyLoad: true,
      contain: true,
      cellAlign: 'center',
      groupCells: false,
      imagesLoaded: true,
      lazyLoad: 1,
      percentPosition: false,
      wrapAround: setWrapAround,
      arrowShape: {
        x0: 10,
        x1: 50, y1: 50,
        x2: 70, y2: 50,
        x3: 30
      }
    });

    adjustCarouselBtns(billboard);
    loadVideos(flickity);
    assignNightMode(billboard, flickity);

    billboard.addEventListener('select.flickity', () => {
      assignNightMode(billboard, flickity);
    });
  });
}

function loadVideos(flickity) {
  const videoWrapper = document.querySelectorAll('.scenery .video');

  videoWrapper.forEach(wrapper => {
    const videos = wrapper.querySelectorAll('video');

    videos.forEach(video => {
      video.addEventListener('loadeddata', event => {
        const cell = flickity.getParentCell(event.target);
        flickity.cellSizeChange(cell && cell.element);
      });
    });
  });
}

function assignNightMode(billboard, flickity) {
  const currentSlide = flickity.selectedElement.childNodes[1];
  if (currentSlide) {
    const nightMode = currentSlide.dataset.nightMode;
    billboard.dataset.nightMode = nightMode;
  }
}

export function checkForBillboardSliders() {
  const billboards = document.querySelectorAll('.gallery');
  if (billboards.length) {
    loadBillboardSliders(billboards);
  }
 }
