<template>
  <portal to="modals" v-if="requested && !disablePortal">
    <div class="modal-wrapper" :class="{ 'active' : requested }" ref="modal" @click="close()" v-on:keyup.esc="close()">
      <div class="modal-container" :class="[containerClass]" @click.stop>
        <slot name="before-content">
          <a class="btn-close" tabindex="0" @click="close()" v-on:keyup.enter="close()" >
            <span class="icon"></span>
            <i>Close</i>
          </a>
        </slot>

        <div class="modal-content">
          <slot></slot>
        </div>
        <div class="loading overlay" v-if="loading">
          <div class="loading-spinner"></div>
        </div>

        <slot name="after-content">
        </slot>
      </div>
    </div>
  </portal>
  <div v-else-if="disablePortal">
    <slot></slot>
  </div>
</template>

<script>
const KEYCODE_ESCAPE = 27;

export default {
  props: {
    requested: {
      default: false,
    },
    name: {
      default: null
    },
    loading: {
      default: false,
    },
    disablePortal: {
      default: false
    },
    containerClass: {
      default: null,
    }
  },
  computed: {
    isOpen() {
      return this.requested;
    }
  },
  watch: {
    'requested': {
      handler: function(requested) {
        let $class = 'modal-open', $el = document.querySelector('body');

        if (requested && !this.disablePortal) {
          $el.classList.add($class);
        } else {
          $el.classList.remove($class);
        }
      },
      immediate: true
    }
  },
  mounted() {
    const closeOnEscapeKey = (e) => {
      if (this.isOpen && e.keyCode == KEYCODE_ESCAPE) {
        this.close();
      }
    }
    
    document.addEventListener('keydown', closeOnEscapeKey);

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', closeOnEscapeKey);
    })
  },
  data() {
    return {
      //
    }
  },
  methods: {
    close() {
      this.$emit('modal-closed', { name: this.name });
    }
  },
}
</script>