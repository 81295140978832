import { drag, cache as dragCache } from '../drag.js';

function loadAbImageDrag(abImages) {
  abImages.forEach(imageContainer => {
    const dragger = imageContainer.querySelector('.dragger');
    const imageB = imageContainer.querySelector('.image-b');

    const resizeImage = function() {
      imageB.style.width = `${dragCache.widthValue}%`;
    };

    const draggable = function() {
      const padding = 10;
      drag(this, imageContainer, padding);
      imageB.classList.add('resizable');
      imageContainer.addEventListener('mousemove', resizeImage);
    }

    const removeImageResize = function() {
      imageContainer.removeEventListener('mousemove', resizeImage);
    }

    dragger.addEventListener('mousedown', draggable);
    imageContainer.addEventListener('mouseup', removeImageResize);
    imageContainer.addEventListener('mouseleave', removeImageResize);
  });
}

export function checkForAbImages() {
  const abImages = document.querySelectorAll('.ab_image_drag');

  if (abImages.length) {
    loadAbImageDrag(abImages);
  }
}
