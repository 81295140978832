import Flickity from 'flickity';
import 'flickity-imagesloaded';

import { adjustCarouselBtns } from '../adjust_carousel_btns.js';

function loadQuoteCarousels(carousels) {
  carousels.forEach(carousel => {
    new Flickity( carousel, {
      adaptiveHeight: true,
      bgLazyLoad: true,
      imagesLoaded: true,
      lazyLoad: true,
      percentPosition: false,
      arrowShape: {
        x0: 10,
        x1: 50, y1: 50,
        x2: 70, y2: 50,
        x3: 30
      }
    });

    adjustCarouselBtns(carousel);
  });
};

export function checkForQuoteCarousels() {
  const carousels = document.querySelectorAll('.content-carousel');

  if (carousels.length) {
    loadQuoteCarousels(carousels);
  }
}
