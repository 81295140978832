import packery from "isotope-packery";
import Vue from "vue";

export default Vue.extend({
  props: ["entries", "options"],
  data() {
    return {
      isInitialized: false,
      filterKey: null,
      filterType: "none",
      isotopeOptions: {
        filter: this.filterType,
        percentPosition: true,
        masonry: {
          columnWidth: ".grid-sizer",
          columnWidth: ".gutter-sizer",
        },
        stagger: 30,
        getFilterData: {
          none: function() {
            return true;
          },
          belongsToCategory: function(entry) {
            if (entry !== undefined && this.options !== undefined) {
              return entry.categories.includes(this.options.filterKey);
            }
            return true;
          },
        },
        filterKey: null,
        ...this.options,
      },
    };
  },
  mounted() {
    this.isInitialized = true;
  },
  render(h) {
    return this.$scopedSlots.default({
      tiles: this.entries,
      options: this.isotopeOptions,
      filter: this.filter,
      updateUrl: this.updateQueryStringParameter,
      isReady: this.isInitialized,
    });
  },
  methods: {
    filter(categoryJson, isotopeInstance) {
      const cat = JSON.parse(categoryJson);
      this.isotopeOptions.filterKey = cat.id;
      this.updateQueryStringParameter(cat.slug);
      isotopeInstance.filter(cat.id == null ? "none" : "belongsToCategory");
      return true;
    },
    updateQueryStringParameter(slug) {
      if ("URLSearchParams" in window) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set("category", slug);
        var newRelativePathQuery =
          window.location.pathname + "?" + searchParams.toString();
        history.pushState(null, "", newRelativePathQuery);
      }
      return true;
    },
  },
});
