import { drag, cache as dragCache } from '../drag.js';

function loadTimelapseDrag(timelapses) {
  const timelapseContainers = document.querySelectorAll('.timelapse-container');
  timelapseContainers.forEach(container => getBackground(container));

  timelapses.forEach(timelapse => {
    const dragger = timelapse.querySelector('.slider-icon');
    const imagesCount = timelapse.dataset.timelapseNumber;
    const sliderSplit = (100 / imagesCount);

    const draggable = function() {
      drag(this, timelapse);
      dragCache.draggerWidth = 0;
      timelapse.addEventListener('mousemove', changeImage);
    }

    const removeChangeImage = function() {
      timelapse.removeEventListener('mousemove', changeImage);
    }

    const changeImage = function() {
      const widthNum = dragCache.widthValue < 1 ? 1 : dragCache.widthValue;
      const currentImageNum = Math.ceil(widthNum / sliderSplit);
      const nextImageNum = timelapse.querySelector('.current').dataset.imageNumber;

      if (currentImageNum !== nextImageNum) {
        const container = timelapse.querySelector('.timelapse-figure');
        const currentImage = container.querySelector(`img[data-image-number="${currentImageNum}"]`);
        const images = container.querySelectorAll('img');

        currentImage.classList.remove('not-in-view');
        container.querySelector('.current').classList.remove('current');
        currentImage.classList.add('current');

        images.forEach(image => {
          if (!image.classList.contains('current')) {
            image.classList.add('not-in-view');
          }
        });
      }
    };

    dragger.addEventListener('mousedown', draggable);
    timelapse.addEventListener('mouseup', removeChangeImage);
    timelapse.addEventListener('mouseleave', removeChangeImage);

    if (timelapse.classList.contains('is-hidden')) {
      timelapse.classList.remove('is-hidden');
    }
  });
}

function getBackground(container) {
  const background = container.dataset.timelapseBkgrdImage;
  const backgroundColor = container.dataset.timelapseBkgrd;
  const opacity = container.dataset.timelapseOpacity;
  container.style.backgroundImage = `url(${background})`;
  container.style.backgroundColor = backgroundColor;

  if (opacity) {
    const el = document.createElement('div');
    el.classList.add('add-opacity');
    el.style.opacity = opacity;
    container.insertBefore(el, container.firstChild);
  }
}

export function checkForTimelapse() {
  const timelapses = document.querySelectorAll('.timelapse');
  if (timelapses.length) {
    loadTimelapseDrag(timelapses);
  }
}
