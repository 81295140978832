function activateFormSubmit(forms) {
  forms.forEach((form, index) => {
    const $form = $(form);
    $(form).submit(event => {
      event.preventDefault();
      showSpinner($form, index);
      submitForm($form, index);
    });
  });
}

function submitForm($form, index) {
  $form.removeClass('error');
  $form.removeClass('success');
  $form.find('.message').remove();

  $.ajax({
    type : 'POST',
    url : 'sprout-forms/entries/save-entry',
    data : $form.serialize(),
    dataType : 'json',
    encode: true
  }).done(data => {
    $form.children(`.thinking_${index}`).fadeOut(300, () => { $(this).remove(); });
    var message = {
      type: 'success',
      details: 'You\'re all set!'
    };

    if (!data.success) {
      const handle = $form.find('input[name="handle"]').val();
      const errors = data.errors[handle].join(', ');
      message.type = 'error';
      message.details = errors;
      $form.addClass('error');
    } else {
      $form.addClass('success');
    }

    showMessage($form, message);
  });
}

function showMessage($form, messageData) {
  const message = `<div class="message ${messageData.type}">${messageData.details}</div>`;

  $form.append(message);
  $form.children('.message').fadeIn();
}

function showSpinner($form, index) {
  const spinner = `
    <div class="thinking_${index}">
      <div class="spinner-wrapper">
        <div class="thinking-spinner"></div>
      </div>
    </div>
  `;

  $form.append(spinner);
  $form.children(`.thinking_${index}`).fadeIn();
}

export function checkForForms() {
  const forms = document.querySelectorAll('form[method="post"]');
  if (forms.length) {
    activateFormSubmit(forms);
  }
}
