export function checkForBackToTop() {
  const backToTop = document.querySelector('.back_to_top');

  if (backToTop) {
    backToTop.addEventListener('click', (event) => {
      event.preventDefault();
      $('html, body').delay(100).animate({
        scrollTop: 0
      }, 600);
    });
  }
}
